<template>
  <div class="container-fluid products-categories">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <div class="col-auto my-auto d-flex align-items-center">
              <h5>Categories</h5>

              <div class="col text-end">
                <button
                  @click="manageProducts()"
                  class="btn btn-outline-secondary btn-sm me-2"
                >
                  <i class="far fa-edit me-1"></i>
                  Products
                </button>
                <button
                  @click="addCategory()"
                  class="btn btn-outline-primary btn-sm"
                >
                  <i class="far fa-plus me-1"></i>
                  Add category
                </button>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-auto my-auto align-items-center form-inline search-box">
                <input
                  type="text"
                  v-model="searchTerm"
                  @input="search"
                  class="form-control form-control-sm"
                  autocomplete="on"
                  placeholder="Enter search query"
                />
              </div>
              <div class="col-auto ms-auto form-inline">
                <div class="my-4">
                  <button
                    class="btn btn-sm btn-outline-dark me-1 cursor-pointer"
                    :class="sortDirection != 'desc' ? 'active' : ''"
                    @click="sortDir('asc')"
                  >
                    <i class="far fa-sort-size-up"></i>
                  </button>
                  <button
                    class="btn btn-sm btn-outline-dark cursor-pointer"
                    :class="sortDirection == 'desc' ? 'active' : ''"
                    @click="sortDir('desc')"
                  >
                    <i class="far fa-sort-size-down"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="row mb-5" v-if="!loading">
              <div class="col">
                <table v-if="categories.length" class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Products</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(c) in categories" :key="c.id" @click="viewCategory(c)">
                      <td>{{ c.title }}</td>
                      <td>{{ c.products_count }}</td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="!categories.length" class="my-2">
                  No categories found
                </div>
              </div>
            </div>
            <loading class="mb-5" v-else></loading>
          </div>

          <div class="card-footer">
            <div class="row pagination">
              <div class="col">
                <button
                  @click="fetchCategories(pagination.first_page_url)"
                  :disabled="!pagination || !pagination.first_page_url
                    || pagination.current_page == 1"
                  class="btn btn-light btn-sm me-3"
                >
                  First Page
                </button>
                <button
                  @click="fetchCategories(pagination.prev_page_url)"
                  :disabled="!pagination || !pagination.prev_page_url"
                  class="btn btn-light btn-sm"
                >
                  Previous Page
                </button>
              </div>
              <div class="col text-center my-auto">
                <i class="small">
                  Page {{ pagination ? pagination.current_page : 1 }}
                  of
                  {{ pagination ? pagination.last_page: 1 }}
                </i>
              </div>
              <div class="col text-end">
                <button
                  @click="fetchCategories(pagination.next_page_url)"
                  :disabled="!pagination || !pagination.next_page_url"
                  class="btn btn-light btn-sm me-3"
                >
                  Next Page
                </button>
                <button
                  @click="fetchCategories(pagination.last_page_url)"
                  :disabled="!pagination || !pagination.last_page_url
                    || pagination.current_page == pagination.last_page"
                  class="btn btn-light btn-sm"
                >
                  Last Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import debounce from "debounce";
  import Loading from "@/views/components/loader/Loading";

  export default {
    props: [],
    data() {
      return {
        categories: [],
        loading: true,
        pagination: null,
        searchParam: "",
        searchTerm: "",
        selectedCategory: {},
        sortDirection: "asc"
      };
    },
    methods: {
      addCategory() {
        this.$router.push('/settings/products/categories/create');
      },
      fetchCategories: debounce(function(paginationUrl) {
        var url = paginationUrl
          ? paginationUrl
          : process.env.VUE_APP_API_URL + "/products/categories";

        if (this.searchParam) {
          url = this.updateQueryStringParameter(url, "search_term", this.searchParam);
        }

        if (this.sortDirection) {
          url = this.updateQueryStringParameter(url, "sort_direction", this.sortDirection);
        }

        this.loading = true;

        this.$axios.get(url).then(({ data }) => {
          this.categories = data.data;
          this.pagination = data;
          this.loading = false;
        });
      }, 300),
      manageProducts() {
        this.$router.push('/settings/products');
      },
      search: debounce(function() {
        if (this.searchTerm.length > 2) {
          this.searchParam = this.searchTerm;
        } else {
          this.searchParam = "";
        }
        this.fetchCategories();
      }, 300),
      sortDir(direction) {
        this.sortDirection = direction;
        this.fetchCategories();
      },
      updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf("?") !== -1 ? "&" : "?";

        if (uri.match(re)) {
          return uri.replace(re, "$1" + key + "=" + value + "$2");
        } else {
          return uri + separator + key + "=" + value;
        }
      },
      viewCategory(category) {
        this.$router.push('/settings/products/categories/' + category.id);
      }
    },
    created() {
      this.fetchCategories();
    },
    computed: {
      user() {
        return this.$store.getters['auth/user'];
      },
      path() {
        return this.$stash.path;
      }
    },
    components: {
      Loading
    }
  };
</script>

<style scoped>
  .table thead > tr > th {
    font-size: 0.9rem;
    border-top: 0px;
    border-bottom: 1px;
  }

  @media (max-width: 768px) {
    .spinner-border {
      margin-top: 2rem;
    }

    .card-header > .d-flex {
      flex-direction: column;
    }

    hr {
      display: none;
    }

    .search-box {
      width: 100%;
    }

    .pagination {
      flex-direction: column;
    }

    .btn-outline-primary,
    .btn-outline-secondary,
    .pagination > .col > button {
      width: 100%;
    }

    .pagination > .col > button {
      margin-top: 0.5rem;
    }
  }
</style>